import { NewPlan } from 'model/plan';
import { PRICES } from '../../../../data/prices';

type AvailablePlan = {
  plan: NewPlan;
  seats: number;
  amount: number;
  currency: 'EUR' | 'USD';
};

export const getPlanId = (plan: string) =>
  process.env[`REACT_APP_${plan}`] || '';

export const availablePlans: { [key: string]: AvailablePlan } = {
  [getPlanId('PERSONAL_USD')]: {
    plan: 'PERSONAL',
    seats: 1,
    amount: PRICES.USD.PERSONAL,
    currency: 'USD',
  },
  [getPlanId('PERSONAL_EUR')]: {
    plan: 'PERSONAL',
    seats: 1,
    amount: PRICES.EUR.PERSONAL,
    currency: 'EUR',
  },
  [getPlanId('PROFESSIONAL_USD')]: {
    plan: 'PROFESSIONAL',
    seats: 1,
    amount: PRICES.USD.PROFESSIONAL,
    currency: 'USD',
  },
  [getPlanId('PROFESSIONAL_EUR')]: {
    plan: 'PROFESSIONAL',
    seats: 1,
    amount: PRICES.EUR.PROFESSIONAL,
    currency: 'EUR',
  },
  [getPlanId('TEAM_USD_5')]: {
    plan: 'TEAM',
    seats: 5,
    amount: PRICES.USD.TEAM_5,
    currency: 'USD',
  },
  [getPlanId('TEAM_EUR_5')]: {
    plan: 'TEAM',
    seats: 5,
    amount: PRICES.EUR.TEAM_5,
    currency: 'EUR',
  },
  [getPlanId('TEAM_USD_10')]: {
    plan: 'TEAM',
    seats: 10,
    amount: PRICES.USD.TEAM_10,
    currency: 'USD',
  },
  [getPlanId('TEAM_EUR_10')]: {
    plan: 'TEAM',
    seats: 10,
    amount: PRICES.EUR.TEAM_10,
    currency: 'EUR',
  },
  [getPlanId('TEAM_USD_25')]: {
    plan: 'TEAM',
    seats: 25,
    amount: PRICES.USD.TEAM_25,
    currency: 'USD',
  },
  [getPlanId('TEAM_EUR_25')]: {
    plan: 'TEAM',
    seats: 25,
    amount: PRICES.EUR.TEAM_25,
    currency: 'EUR',
  },
  [getPlanId('TEAM_USD_50')]: {
    plan: 'TEAM',
    seats: 50,
    amount: PRICES.USD.TEAM_50,
    currency: 'USD',
  },
  [getPlanId('TEAM_EUR_50')]: {
    plan: 'TEAM',
    seats: 50,
    amount: PRICES.EUR.TEAM_50,
    currency: 'EUR',
  },
  [getPlanId('TEAM_USD_100')]: {
    plan: 'TEAM',
    seats: 100,
    amount: PRICES.USD.TEAM_100,
    currency: 'USD',
  },
  [getPlanId('TEAM_EUR_100')]: {
    plan: 'TEAM',
    seats: 100,
    amount: PRICES.EUR.TEAM_100,
    currency: 'EUR',
  },
  unlimited: {
    plan: 'UNLIMITED',
    seats: Infinity,
    amount: Infinity,
    currency: 'EUR',
  },
};
