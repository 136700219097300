import { queryCache } from 'data/cache';

export const TOKEN_KEY = 'yamm:auth-token';
export const TOKEN_EXPIRATION_KEY = 'yamm:auth-token-expires-at';
export const TOKEN_LAST_LOGIN = 'yamm:auth-last-login';

export type TokenQuery = {
  token: string | null;
  expiresAt: number | null;
};

function clear() {
  localStorage.removeItem(TOKEN_KEY);
  localStorage.removeItem(TOKEN_EXPIRATION_KEY);
  localStorage.removeItem(TOKEN_LAST_LOGIN);
  queryCache.setQueryData<TokenQuery>('token', {
    token: null,
    expiresAt: null,
  });
}

function set(accessToken: string, expiresAt: number) {
  localStorage.setItem(TOKEN_EXPIRATION_KEY, `${expiresAt}`);
  localStorage.setItem(TOKEN_KEY, accessToken);
  queryCache.setQueryData<TokenQuery>('token', {
    token: accessToken,
    expiresAt,
  });
}

function get() {
  const expiresAt = localStorage.getItem(TOKEN_EXPIRATION_KEY);
  return {
    token: localStorage.getItem(TOKEN_KEY),
    expiresAt: expiresAt ? parseInt(expiresAt, 10) : null,
  };
}

export const Token = {
  get,
  set,
  clear,
};
