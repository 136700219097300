import React from 'react';
import { PriceCardProps } from '../../../components/PriceCard/types';
import { PRICES } from '../../../data/prices';

export const PersonalPriceCardData: PriceCardProps = {
  title: 'Personal',
  target: '@gmail.com account',
  price: PRICES.USD.PERSONAL,
  currency: '$',
  billingPeriod: 'year',
  recipients: 400,
  subscribeHref: '/settings/billing?plan=PERSONAL',
  tooltipContent: (
    <>
      You can only send emails with personalized attachments to 100 recipients /
      day. <i>Click to learn more</i>
    </>
  ),
};

export const ProfessionalPriceCardData: PriceCardProps = {
  title: 'Professional',
  target: 'Google Workspace account',
  price: PRICES.USD.PROFESSIONAL,
  currency: '$',
  billingPeriod: 'year',
  recipients: 1500,
  subscribeHref: '/settings/billing?plan=PROFESSIONAL',
  tooltipContent: (
    <>
      This email quota is subject to certain conditions.{' '}
      <i>Click to learn more</i>
    </>
  ),
};
