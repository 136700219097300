import { client } from 'data/client';
import { Input } from 'model/user';

type GetUsersResponse = {
  items: Input[];
};

export async function getUsers(key: string, userId: string) {
  const { data } = await client.get<GetUsersResponse>(
    `/users/${userId}/domain/users`,
  );
  return data.items;
}

export async function postUser() {
  await client.post(`/users`);
}
