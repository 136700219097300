export type Input = {
  customerId: string;
  customerName: string;
  companyName: string;
  vatNumber: string;
  currency: 'EUR' | 'USD';
  address: {
    country: string;
    street: string;
    city: string;
    state: string;
    postalCode: string;
  };
};

export const normalizeBillingInfo = ({
  customerId,
  currency,
  ...input
}: Input) => {
  return {
    name: input?.customerName,
    companyName: input?.companyName,
    vat: input?.vatNumber,
    city: input?.address?.city,
    code: input?.address?.postalCode,
    country: input?.address?.country,
    state: input?.address?.state,
    street: input?.address?.street,
    isBusinessPurchase: currency.toUpperCase() === 'EUR' && !!input?.vatNumber,
  };
};

export type BillingInfo = ReturnType<typeof normalizeBillingInfo>;
