import { Form, Formik } from 'formik';
import React from 'react';
import { useConnect } from './connect';
import { useLogic } from './logic';
import { validationSchema } from './constants';
import {
  Back,
  Button,
  Column,
  Container,
  Content,
  CountrySelect,
  Header,
  Icon,
  Input,
  OrderSummary,
  RadioInput,
  Row,
  ColumnedRow,
  StripeStamp,
  Title,
  BillingMessage,
  FormPlaceholder,
  OrderSummaryPlaceholder,
  Loader,
} from './styles';
import { Props } from './types';

const Pricing = ({ plan, seats }: Props) => {
  const {
    typeOfError,
    error,
    enableReinitialize,
    taxRate,
    taxRateIncluded,
    currency,
    isEuropeanCountry,
    initialValues,
    isLoading,
    handleSubmit,
    handleSeatsChange,
    handleCountryChange,
    handleTypeOfBusinessChange,
    setError,
    disableCheckoutButton,
  } = useConnect({ plan, seats });
  const { amount, quota } = useLogic({ plan, seats, currency });
  return (
    <Container>
      {error !== null ? (
        <BillingMessage
          isOpen
          variant={error.type}
          onClose={() => setError(null)}
          title={error.title}
          message={error.message}
          id={typeOfError || ''}
        />
      ) : null}
      <Header>
        <Back to="/settings/billing">
          <Icon />
        </Back>
        <Title>Billing information</Title>
      </Header>
      {isLoading ? (
        <Content>
          <Column $isLoading>
            <FormPlaceholder />
          </Column>
          <Column>
            <OrderSummaryPlaceholder />
          </Column>
        </Content>
      ) : (
        <Formik
          enableReinitialize={enableReinitialize}
          initialValues={initialValues}
          onSubmit={handleSubmit}
          validationSchema={validationSchema(plan)}
        >
          {({ values, isSubmitting }) => (
            <Form noValidate data-test-id="pricing-form">
              <Content>
                <Column>
                  {plan === 'TEAM' ? (
                    <Row>
                      <Input
                        label="Domain"
                        name="domain"
                        placeholder="example.com"
                      />
                    </Row>
                  ) : null}
                  <Row>
                    <CountrySelect
                      label="Country"
                      name="country"
                      placeholder="Select country"
                      defaultValue={initialValues.country}
                      onChange={handleCountryChange}
                    />
                  </Row>
                  {isEuropeanCountry ? (
                    <Row>
                      <RadioInput
                        label="Is this a business purchase?"
                        name="isBusinessPurchase"
                        onChange={handleTypeOfBusinessChange}
                      />
                      {values.isBusinessPurchase ? (
                        <Input label="VAT ID" name="vat" />
                      ) : null}
                    </Row>
                  ) : null}
                  {isEuropeanCountry && values.isBusinessPurchase ? (
                    <Row>
                      <Input
                        label="Company Name"
                        name="companyName"
                        maxLength={30}
                      />
                    </Row>
                  ) : null}
                  <Row>
                    <Input label="Name" name="name" maxLength={30} />
                  </Row>
                  <Row>
                    <Input label="Street address" name="street" />
                  </Row>
                  <ColumnedRow>
                    <Input label="City" name="city" />
                    <Input isOptional label="State / Province" name="state" />
                  </ColumnedRow>
                  <Row>
                    <Input isOptional label="Postal code" name="code" />
                  </Row>
                </Column>
                <Column>
                  <OrderSummary
                    amount={amount}
                    name="seats"
                    plan={plan}
                    quota={quota}
                    currency={currency}
                    taxRate={taxRate}
                    taxRateIncluded={taxRateIncluded}
                    onSeatsChange={handleSeatsChange}
                  />
                  <Button
                    type="submit"
                    disabled={isSubmitting || disableCheckoutButton}
                  >
                    {isSubmitting ? <Loader /> : 'Go to checkout'}
                  </Button>
                  <StripeStamp />
                </Column>
              </Content>
            </Form>
          )}
        </Formik>
      )}
    </Container>
  );
};

export default Pricing;
