import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import Layout from 'containers/Common/Layout';
import { SnackbarProvider } from 'notistack';
import TemplatesProvider from 'data/context/TemplatesContext';
import MailMerges from './MailMerges';
import Unsubscriptions from './Unsubscriptions';
import Settings from './Settings';
import TeamReport from './TeamReport';
import { useConnect } from './connect';
import Templates from './Templates';
import { StackDriverLoggingProvider } from '../../data/context/StackDriverLogging';

const Main = () => {
  const { isTesting, me, switchAccount, logout, cannon } = useConnect();

  if (isTesting && !me) return null;

  return (
    <SnackbarProvider
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
    >
      <TemplatesProvider>
        <StackDriverLoggingProvider>
          <Layout
            onLogout={logout}
            onAccountSwitch={switchAccount}
            cannon={cannon}
            {...me}
          >
            <Switch>
              <Route component={MailMerges} path="/mail-merges" />
              <Route component={Unsubscriptions} path="/unsubscriptions" />
              <Route component={Settings} path="/settings" />
              <Route component={TeamReport} path="/team-report" />
              <Route component={Templates} path="/templates" />
              <Route path="*">
                <Redirect to="/mail-merges" />
              </Route>
            </Switch>
          </Layout>
        </StackDriverLoggingProvider>
      </TemplatesProvider>
    </SnackbarProvider>
  );
};

export default Main;
