/* eslint-disable import/no-webpack-loader-syntax */
import { useRef } from 'react';
import { useWorker } from 'utils/useWorker';
import sessionWorkerUrl from 'worker-plugin/loader?name=session!./expiration-worker';
import { proxy } from 'comlink';
import { addDays } from 'date-fns';
import { SessionWorker } from './expiration-worker';
import { TokenQuery, TOKEN_LAST_LOGIN } from './token';
import { ErrorType } from './types';

const SESSION_LIFETIME = 15; // days

export function useSessionExpiration() {
  const expirationId = useRef<number | undefined>(undefined);
  const { worker: expirationWorker } = useWorker<SessionWorker>(
    sessionWorkerUrl,
    'ExpirationSessionWorker',
  );

  /**
   * this timeout is responsible of kicking out the user when SESSION_LIFETIME days
   * have passed, if the last login time is not saved we will use the expires at value
   * from the google response. When they log in manually again the last login value
   * will be saved and therefore the session will last until that value
   */
  async function triggerExpiringSessionTimeout({
    data,
    logout,
    setError,
  }: {
    data: TokenQuery;
    logout: () => Promise<void>;
    setError: (error: ErrorType | null) => void;
  }) {
    if (!data?.expiresAt || expirationId.current !== undefined) return;
    const lastLoginValue = localStorage.getItem(TOKEN_LAST_LOGIN);
    let expirationTime;

    if (lastLoginValue) {
      const lastLoginDate = new Date(parseInt(lastLoginValue, 10));
      expirationTime = addDays(lastLoginDate, SESSION_LIFETIME).getTime();
    }

    const timeToExpire = (expirationTime ?? data.expiresAt) - Date.now() - 2000;
    expirationId.current = await expirationWorker.setExpirationTimeout(
      timeToExpire,
      proxy(async () => {
        logout();
        expirationId.current = undefined;
        setError({ type: 'session-expired' });
      }),
    );
  }

  return {
    expirationWorker,
    triggerExpiringSessionTimeout,
  };
}
