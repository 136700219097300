import { queryCache } from 'data/cache';
import { GoogleProfileQuery } from 'data/hooks/useGoogleProfile';
import { Token } from './token';

const instance = () => gapi.auth2.getAuthInstance();
const user = () => instance().currentUser.get();

function getUserData(data: gapi.auth2.BasicProfile) {
  return {
    name: data.getName(),
    imageUrl: data.getImageUrl(),
    email: data.getEmail(),
  };
}

async function refreshAuth() {
  if (!user().isSignedIn()) {
    throw new Error('User is not signed in');
  } else {
    return user()
      .reloadAuthResponse()
      .then((response) => {
        Token.set(response.access_token, response.expires_at);
      });
  }
}

function getAuthData() {
  if (!Object.keys(user()).length) return null;
  const {
    access_token: accessToken,
    expires_at: expiresAt,
  } = user().getAuthResponse();
  const userProfileData = getUserData(user().getBasicProfile());
  return {
    accessToken,
    expiresAt,
    userProfileData,
    reloadAuthResponse: user().reloadAuthResponse,
  };
}

function saveProfileData(
  profile: NonNullable<ReturnType<typeof getAuthData>>['userProfileData'],
) {
  queryCache.setQueryData<GoogleProfileQuery>('googleProfile', profile, {
    cacheTime: Infinity,
  });
}

function signOut() {
  return instance().signOut();
}

export const Google = {
  instance,
  user,
  refreshAuth,
  getAuthData,
  saveProfileData,
  signOut,
};
