import React, { FC, memo } from 'react';
import { format12hTime, formatMonthDay } from 'utils/dates';
import DateTimeCell from 'components/DesktopTable/DateTimeCell';
import { Props } from './types';
import { Column, Datetime, InfoItem, Info, Time } from '../styles';
import {
  RowLinkContainer,
  Link,
  ErrorIcon,
  WarningIcon,
  TitleText,
  TitleGrid,
} from './styles';

const Row: FC<Props> = ({
  url,
  name,
  sent,
  opened,
  clicked,
  date,
  time,
  scheduled = false,
  variant = 'normal',
  ...props
}) => {
  return (
    <RowLinkContainer {...props}>
      <Link to={url}>
        <Column>
          {variant === 'normal' && (
            <TitleText $variant={variant}>{name}</TitleText>
          )}
          {variant === 'error' && (
            <TitleGrid>
              <ErrorIcon />
              <TitleText $variant={variant}>{name}</TitleText>
            </TitleGrid>
          )}
          {variant === 'warning' && (
            <TitleGrid>
              <WarningIcon />
              <TitleText $variant={variant}>{name}</TitleText>
            </TitleGrid>
          )}
          <Info>
            <InfoItem>
              {sent} {scheduled ? 'mails' : 'sent'}
            </InfoItem>
            {opened ? <InfoItem>{opened}% opened</InfoItem> : null}
            {clicked ? <InfoItem>{clicked}% clicked</InfoItem> : null}
          </Info>
        </Column>
        <Column>
          <Datetime>
            {date ? (
              <DateTimeCell value={date} formatFunction={formatMonthDay} />
            ) : null}
            {time ? (
              <Time>
                <DateTimeCell value={time} formatFunction={format12hTime} />
              </Time>
            ) : null}
          </Datetime>
        </Column>
      </Link>
    </RowLinkContainer>
  );
};

export default memo(Row);
