import React, { useState, useEffect } from 'react';

import ISOLogo from 'assets/images/icons/ISO-compliant.svg';
import GCPLogo from 'assets/images/icons/GCP.png';
import GDPRLogo from 'assets/images/icons/GDPR.svg';

import { useAuth } from 'data/context/AuthContext';

import { Nav, NavLink } from '../styles';
import { Wrapper, Container, FooterLink, Footer, Badges, Main } from './styles';
import Progress from './Progress';
import StepOne from './StepOne';
import StepTwo from './StepTwo';
import StepThree from './StepThree';

function renderStep({
  step,
  setStep,
  visited,
  changeVisited,
}: {
  step: number;
  setStep: (step: number) => void;
  visited: boolean;
  changeVisited: (visited: boolean) => void;
}) {
  const handleNext = () => {
    setStep(step + 1);
  };

  switch (step) {
    case 1:
      return <StepOne />;
    case 2:
      return (
        <StepTwo
          visited={visited}
          changeVisited={changeVisited}
          handleNext={handleNext}
        />
      );
    case 3:
      return <StepThree />;
    default:
      return <StepOne />;
  }
}

const SignUp = () => {
  const { token } = useAuth();

  const [currentStep, setCurrentStep] = useState<number>(1);
  const [visited, changeVisited] = useState<boolean>(false);
  useEffect(() => {
    if (!token) {
      setCurrentStep(1);
    } else {
      setCurrentStep(2);
    }
  }, [token]);

  return (
    <Wrapper>
      <Main>
        <Container>
          <Progress
            step={currentStep}
            total={3}
            labels={[
              'Create your account',
              'Install YAMM',
              'Send your first merge',
            ]}
            setStep={setCurrentStep}
          />
          {renderStep({
            step: currentStep,
            setStep: setCurrentStep,
            visited,
            changeVisited,
          })}
        </Container>
      </Main>
      <Footer>
        <div>
          <Nav>
            <NavLink href="https://support.yet-another-mail-merge.com/hc/en-us">
              Help center
            </NavLink>
            <NavLink
              href={`${process.env.REACT_APP_MARKETING_WEBSITE_URL}/terms-of-service`}
            >
              Terms of service
            </NavLink>
            <NavLink
              href={`${process.env.REACT_APP_MARKETING_WEBSITE_URL}/privacy-policy`}
            >
              Privacy policy
            </NavLink>
            <NavLink
              href={`${process.env.REACT_APP_MARKETING_WEBSITE_URL}/cookie-policy`}
            >
              Cookie policy
            </NavLink>
            <NavLink
              href={`${process.env.REACT_APP_MARKETING_WEBSITE_URL}/data-processing-agreement`}
            >
              Data processing agreement
            </NavLink>
          </Nav>
        </div>
        <Badges>
          <FooterLink href="https://storage.googleapis.com/talarian-assets/global/ios27001-cert.pdf">
            <img src={ISOLogo} alt="iso-complient" />
          </FooterLink>
          <FooterLink href="https://cloud.withgoogle.com/partners/detail/?id=talarian">
            <img src={GCPLogo} alt="gc-partner" />
          </FooterLink>
          <FooterLink
            href={`${process.env.REACT_APP_MARKETING_WEBSITE_URL}/data-processing-agreement`}
          >
            <img src={GDPRLogo} alt="gdpr" />
          </FooterLink>
        </Badges>
      </Footer>
    </Wrapper>
  );
};

export default SignUp;
