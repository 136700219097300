import { getManagePlans } from 'data/requests/managePlans';
import { RetryQueryError } from 'model/retryQueryError';
import { useQuery } from 'react-query';
import { excludeQueryParams } from '../../utils/excludeQueryParams';

/**
 * prevent useless fetches
 * manage plans depends returnUrl, but some query params will trigger re-fetch without any change
 */
const QUERY_PARAMS_TO_EXCLUDE = ['quota-conditions'];

export const useManagePlans = ({
  userId,
  returnUrl,
  subscriptionIds = [],
}: {
  userId?: string;
  returnUrl: string;
  subscriptionIds?: string[];
}) => {
  const { data, status } = useQuery(
    [
      'manage-plans',
      userId,
      subscriptionIds,
      excludeQueryParams(returnUrl, QUERY_PARAMS_TO_EXCLUDE),
    ],
    getManagePlans,
    {
      enabled: !!userId && !!returnUrl && subscriptionIds.length > 0,
      staleTime: 5 * 60 * 1000,
      cacheTime: 5 * 60 * 1000,
      refetchOnWindowFocus: false,
      retry: (failureCount, error: RetryQueryError) => {
        if (error.response.status === 404) {
          return false;
        }

        return failureCount < 3;
      },
    },
  );

  const managePlans =
    data &&
    (Object.fromEntries(
      data.map((managePlan) => Object.values(managePlan)),
    ) as { [subscriptionId: string]: string });
  return { managePlans, status };
};
