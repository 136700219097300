import Cookies from 'js-cookie';
import { addDays } from 'date-fns';
import { getRootHost } from 'utils/getRootHost';

export const AuthCookie = {
  options: {
    domain: getRootHost(window.location.host),
    path: '/',
  },
  set() {
    Cookies.set('auth', 'true', {
      ...this.options,
      expires: addDays(new Date(), 36),
    });
  },
  remove() {
    Cookies.remove('auth', { ...this.options });
  },
};
