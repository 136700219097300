import { useCallback, useEffect, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import useQueryParams from '../../../utils/useQueryParams';
import { PersonalPriceCardData, ProfessionalPriceCardData } from './data';
import { PriceCardProps } from '../../../components/PriceCard/types';
import { useAuth } from '../../../data/context/AuthContext';
import { useMe } from '../../../data/hooks/useMe';
import { isGmailAddress } from '../../../utils/userUtils';
import { useQuotaConditionsModalTrigger } from './QuotaConditionsModal/useQuotaConditionsModalTrigger';

export const useConnect = () => {
  const { me } = useMe();
  const { replace } = useHistory();
  const params = useQueryParams();
  const errorType = params.get('error');
  const requestedPlan = params.get('plan');
  const requestedSeats = params.get('seats');

  const { switchAccount } = useAuth();

  const isGmail = me?.email ? isGmailAddress(me.email) : false;
  const cardData: PriceCardProps = useMemo(() => {
    switch (errorType) {
      case 'plan-reserved-for-individual-accounts':
        return ProfessionalPriceCardData;
      case 'plan-reserved-for-workspace-accounts':
      default:
        return PersonalPriceCardData;
    }
  }, [errorType]);

  useEffect(() => {
    const currentLocation = new URL(window.location.href);
    let cont = `/settings/billing?plan=${requestedPlan}`;
    if (requestedSeats) {
      cont += `&seats=${requestedSeats}`;
    }

    currentLocation.searchParams.set('continue', encodeURIComponent(cont));
    replace(currentLocation.pathname + currentLocation.search);
  }, []);

  const onSwitchAccount = useCallback(async () => {
    switchAccount();
  }, [switchAccount]);

  const { trigger } = useQuotaConditionsModalTrigger();

  const onTooltipClick = useCallback(() => {
    if (errorType === 'plan-reserved-for-individual-accounts') {
      trigger();
    } else if (errorType === 'plan-reserved-for-workspace-accounts') {
      window.open(
        'https://support.yet-another-mail-merge.com/hc/en-us/articles/210730829#Paid-version',
      );
    }
  }, [errorType]);

  return {
    errorType,
    cardData,
    me,
    isGmail,
    requestedPlan,
    onSwitchAccount,
    onTooltipClick,
  };
};
