import { useQuery } from 'react-query';

import { getMailMergePoll } from 'data/requests/mailMerge';
import { normalizePoll } from 'model/poll';

export const useMailMergePoll = (userId?: string, campaignId?: string) => {
  const { data, status } = useQuery(
    ['mail-merge-poll', userId, campaignId],
    getMailMergePoll,
    {
      enabled: !!userId && !!campaignId,
    },
  );
  const poll = data && data.length > 0 && normalizePoll(data);
  return { poll, status };
};
