/* eslint-disable import/no-webpack-loader-syntax */
import React, { memo } from 'react';
import { ExporterComponent } from 'components/DesktopTable/types';

import ExportToCsv from 'components/ExportToCsv';
import transformerWorkerUrl from 'worker-plugin/loader?name=scheduledMailMerges!../transformer';
import { useWorker } from 'utils/useWorker';
import { ScheduledCampaign } from 'model/scheduledCampaign';
import { captureException } from '@sentry/minimal';
import { ScheduledMailMergesWorker } from '../transformer';
import { getUserDataForLogging } from '../../../../../utils/logging';
import { useStackDriverLogging } from '../../../../../data/context/StackDriverLogging';

const Exporter: ExporterComponent<ScheduledCampaign[]> = ({ data }) => {
  const { worker: transformer } = useWorker<ScheduledMailMergesWorker>(
    transformerWorkerUrl,
    'ScheduledMailMergesTransformer',
  );
  const { getUser, logToStackDriver } = useStackDriverLogging();
  const onDone = () => {
    // log to the stack driver unsubscribed emails
    try {
      const user = getUserDataForLogging(getUser());
      logToStackDriver({
        timestamp: Date.now(),
        user_id: user.user_id,
        user_installation_timestamp: user.user_installation_timestamp,
        plan: user.plan,
        is_biz_user: user.is_biz_user,
        domain: user.domain,
        event_type: 'csv_export',
        export_type: 'scheduled',
        message: 'User exported scheduled campaigns (csv_export)',
        list_of_merge_ids: data.map((d) => d.id),
      });
    } catch (e) {
      captureException(e);
    }
  };

  return (
    <ExportToCsv
      data={data}
      transformer={transformer}
      fileName={`Exported_Scheduled_Mail_merges_${new Date().toDateString()}`}
      onDone={onDone}
    />
  );
};

export default memo(Exporter);
