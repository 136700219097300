export type Input = {
  taxRate: string;
  currency: string;
};

export const normalizePaymentInfo = ({ currency, taxRate }: Input) => ({
  currency: currency as 'EUR' | 'USD',
  taxRate: Number(taxRate),
});

export type PaymentInfo = ReturnType<typeof normalizePaymentInfo>;
