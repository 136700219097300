import styled from 'styled-components';
import { from } from 'styles/media';
import DefaultPlan from 'components/Plan';
import addAlpha from 'utils/addAlpha';
import DefaultBillingMessage from 'components/BillingMessage';

export const Container = styled.div`
  --maxWidth: 58.5rem;
  margin: 0 auto;
  padding: 2.5rem 1rem;
  display: flex;
  flex-direction: column;

  ${from.tablet} {
    display: grid;
    grid-template-columns: auto var(--maxWidth) auto;

    & > * {
      grid-column: 2;
    }
  }

  ${from.laptop} {
    padding-top: 2.1875rem;
  }
`;

export const BillingMessage = styled(DefaultBillingMessage)`
  margin-bottom: 3.375rem;
`;

export const Section = styled.section`
  & + & {
    padding-top: 2rem;
    ${from.tablet} {
      padding-top: 3.3125rem;
    }
  }
`;

export const Header = styled.header`
  margin-bottom: 1.8125rem;
`;

export const Title = styled.h6`
  color: ${({ theme }) => theme.colors.gray4};
  font-size: 1.5rem;
  line-height: 1.875rem;
`;

export const PlansContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 8px;

  ${from.tablet} {
    row-gap: 0;
    box-shadow: 0rem 0.125rem 0.5rem rgba(152, 152, 152, 0.2);
    border-radius: 0.3125rem;
    overflow: hidden;
  }
`;

export const WantToUpgradeMessage = styled.p`
  font-size: 0.875rem;
  line-height: 160%;
  color: ${({ theme }) => theme.colors.gray3};
  font-family: ${({ theme }) => theme.fonts.inter};
  margin-top: 1rem;
`;

export const PurchaseAdditionalMessage = styled(WantToUpgradeMessage)`
  margin-top: 0.75rem;
`;

export const Plan = styled(DefaultPlan)`
  ${from.tablet} {
    && {
      border-radius: 0;
      box-shadow: none;
    }

    & + & {
      border-top: 0.0625rem solid
        ${({ theme }) => addAlpha(theme.colors.gray2, 75)};
    }
  }
`;

export const LoaderContainer = styled.div`
  margin-top: 40vh;
  width: 100%;
  display: flex;
  justify-content: center;

  * {
    color: ${({ theme }) => theme.colors.blue800};
  }
`;

export const StrongText = styled.span`
  font-weight: bold;
  display: block;
`;
