import React, {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { GoogleLoginResponse, useGoogleLogin } from 'react-google-login';
import { QueryStatus, useQuery } from 'react-query';
import { queryCache } from 'data/cache';
import { useIntent } from 'utils/useIntent';
import { useHistory } from 'react-router-dom';
import { useMe } from 'data/hooks/useMe';
import { AxiosError } from 'axios';
import { captureException, setUser } from '@sentry/minimal';
import LoadingScreen from 'components/LoadingScreen';
import { AnimatePresence } from 'framer-motion';
import { useCreateUser } from 'data/hooks/useUsers';
import IncognitoMode from 'containers/Common/IncognitoMode';
import { AuthContextProps, ErrorType } from './types';
import { useSessionExpiration } from './useSessionExpiration';
import { useSessionRefresher } from './useSessionRefresher';
import { Token, TokenQuery, TOKEN_KEY } from './token';
import { AuthCookie } from './cookie';
import { Google } from './google';

export const GOOGLE_SCOPE =
  'email profile https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/userinfo.profile openid';

const AuthContext = createContext<AuthContextProps>({
  token: undefined,
  clientId: '',
  onLogin: async () => {},
  onSignUp: async () => {},
  askForPermission: async () => {},
  onFailure: () => {},
  logout: () => {},
  switchAccount: () => {},
  getUserStatus: QueryStatus.Idle,
  loading: false,
  refreshing: false,
  error: null,
});

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }: { children: ReactNode }) => {
  const { push } = useHistory();
  const { goToIntent } = useIntent();
  const [error, setError] = useState<ErrorType | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [refreshing, setRefreshing] = useState<boolean>(true);
  const [gapiLoaded, setGapiLoaded] = useState<boolean>(false);
  const clientId = useRef(process.env.REACT_APP_GOOGLE_CLIENT_ID).current;

  const {
    expirationWorker,
    triggerExpiringSessionTimeout,
  } = useSessionExpiration();

  const { refreshWorker, triggerRefreshSessionTimeout } = useSessionRefresher();

  const { data } = useQuery<TokenQuery>('token', async () => Token.get(), {
    cacheTime: Infinity,
    initialData: Token.get,
  });

  const { createUser, status: createUserStatus } = useCreateUser();

  const { status: getUserStatus, refetch: getUser } = useMe({
    enabled: gapiLoaded,
    refetchOnWindowFocus: !!data?.token,
    retryDelay: () => 500,
    retry: (failureCount, e) => {
      if (e != null) {
        captureException(e as AxiosError);
      }

      if (failureCount > 0) {
        Token.clear();
        return false;
      }
      if ((e as AxiosError).response?.status === 401) {
        Google.refreshAuth().catch(() => {
          Token.clear();
        });
      }
      if ((e as AxiosError).response?.status === 404) {
        setError({ type: 'no-user', payload: '' });
        Token.clear();
        return false;
      }

      return true;
    },
    onSettled: () => {
      setRefreshing(false);
    },
  });

  const logout = useCallback(async () => {
    try {
      await refreshWorker.clearRefreshInterval();
      await expirationWorker.clearExpirationTimeout();
      await Google.signOut();
      Token.clear();
      queryCache.clear();
      AuthCookie.remove();
      setUser(null);
      push('/login');
    } catch (e) {
      captureException(e);
    }
  }, [refreshWorker, expirationWorker, push]);

  const onFailure = useCallback((reason) => {
    if (reason?.details?.includes('Cookies are not enabled')) {
      setError({ type: 'incognito-mode' });
    } else {
      if (reason?.details) {
        captureException(new Error(reason.details));
      } else if (reason?.error !== 'popup_closed_by_user') {
        captureException(new Error('Unknown google error'), {
          extra: {
            error: reason?.error,
          },
        });
      }

      setError({ type: 'google' });
    }
  }, []);

  useEffect(() => {
    if (!data || !gapiLoaded) return;
    triggerRefreshSessionTimeout(data);
    triggerExpiringSessionTimeout({ data, logout, setError });
  }, [
    data,
    gapiLoaded,
    triggerRefreshSessionTimeout,
    triggerExpiringSessionTimeout,
    logout,
    setError,
  ]);

  useEffect(() => {
    function onInit() {
      gapi.auth2
        .init({
          client_id: clientId,
          cookie_policy: 'single_host_origin',
          scope: GOOGLE_SCOPE,
        })
        .then(
          () => {
            Google.refreshAuth()
              .then(() => {
                // clear cache that may have an outdated token at the initial time
                queryCache.clear();
                const profile = Google.getAuthData()?.userProfileData;
                if (profile) {
                  setUser({
                    email: profile.email,
                  });
                  Google.saveProfileData(profile);
                }
                AuthCookie.set();
                setGapiLoaded(true);
              })
              .catch(() => {
                Token.clear();
                AuthCookie.remove();
                setRefreshing(false);
              });
          },
          (reason: any) => {
            onFailure(reason);
            setGapiLoaded(true);
          },
        );
    }
    gapi.load('auth2', onInit);
  }, [clientId]);

  const onLogin = useCallback(
    async (params?: { createAccount: boolean }) => {
      const createAccount = params?.createAccount;
      setError(null);
      setLoading(true);
      const authData = Google.getAuthData();
      if (!authData) {
        // eslint-disable-next-line no-console
        console.error('There is no auth data');
        setLoading(false);
        return;
      }
      setUser({
        email: authData.userProfileData.email,
      });

      localStorage.setItem(TOKEN_KEY, authData.accessToken);
      const user = await getUser();
      if (!user && !createAccount) {
        captureException(new Error('User not found'));
        Token.clear();
        AuthCookie.remove();
        setError({
          type: 'no-user',
          payload: authData.userProfileData.email,
        });
        setLoading(false);
      } else {
        if (!user) {
          try {
            localStorage.setItem(TOKEN_KEY, authData.accessToken);
            await createUser();
            await getUser();
          } catch (e) {
            captureException(e);
            setError({ type: 'can-not-create-user' });
            setLoading(false);
            Token.clear();
            return;
          }
        }
        const { expiresAt, accessToken, userProfileData } = authData;
        Token.set(accessToken, expiresAt);
        AuthCookie.set();
        Google.saveProfileData(userProfileData);
        goToIntent();
        setLoading(false);
        setGapiLoaded(true);
        setError(null);
      }
    },
    [getUser, goToIntent],
  );

  const askForPermission = useCallback((scope: string) => {
    const options = new gapi.auth2.SigninOptionsBuilder();
    options.setScope(scope);
    const user = gapi.auth2.getAuthInstance().currentUser.get();
    return (user.grant(options) as Promise<GoogleLoginResponse>).then(
      (success) => {
        const response = success.getAuthResponse();
        Token.set(response.access_token, response.expires_at);
      },
    );
  }, []);

  const onSwitchAccount = useCallback(async () => {
    setRefreshing(true);
    Token.clear();
    queryCache.clear();
    await refreshWorker.clearRefreshInterval();
    await expirationWorker.clearExpirationTimeout();
    await onLogin();
    setRefreshing(false);
  }, [onLogin, refreshWorker, expirationWorker]);

  const { signIn: switchAccount } = useGoogleLogin({
    onSuccess: onSwitchAccount,
    onFailure,
    clientId: clientId || '',
    scope: GOOGLE_SCOPE,
    prompt: 'select_account',
  });

  const onSignUp = useCallback(() => onLogin({ createAccount: true }), [
    onLogin,
  ]);

  const token = data?.token;

  const isLoading =
    (getUserStatus === QueryStatus.Loading && !token) ||
    refreshing ||
    createUserStatus === QueryStatus.Loading;

  if (error && error.type === 'incognito-mode') {
    return <IncognitoMode />;
  }

  return (
    <AuthContext.Provider
      value={{
        token,
        clientId,
        onLogin,
        onSignUp,
        onFailure,
        logout,
        switchAccount,
        getUserStatus,
        askForPermission,
        loading,
        refreshing,
        error,
      }}
    >
      <AnimatePresence>{isLoading && <LoadingScreen />}</AnimatePresence>
      {!isLoading ? children : null}
    </AuthContext.Provider>
  );
};
