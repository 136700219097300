import React, { FC, memo } from 'react';
import firstUpper from 'lodash.upperfirst';
import testingVar from 'utils/testingVar';
import SeatsSelect from './SeatsSelect';
import { Props } from './types';
import {
  Container,
  PlanSummary,
  PlanItem,
  Item,
  Divider,
  Invoice,
  Amount,
  Info,
  SecondaryInfo,
  Tooltip,
  InfoButton,
  InfoLink,
  InfoIcon,
  Cost,
  SecondaryCost,
  BillFrequency,
  TooltipStyles,
} from './styles';
import useLogic, { useFormattedAmounts } from './logic';
import QuotaConditionsModal from '../../containers/Common/WrongPlanChoice/QuotaConditionsModal';

const OrderSummary: FC<Props> = ({
  amount,
  className,
  currency = 'USD',
  name,
  plan,
  quota,
  taxRate,
  taxRateIncluded,
  onSeatsChange,
}) => {
  const { seats, handleSeatsChange, triggerQuotaModal } = useLogic({
    name,
    onSeatsChange,
  });
  const {
    amountFormatted,
    taxesFormatted,
    totalWithTaxes,
  } = useFormattedAmounts({ amount, currency, taxRate, taxRateIncluded });

  return (
    <Container className={className}>
      <QuotaConditionsModal />
      <PlanSummary>
        <PlanItem data-test-id="plan-name">
          {firstUpper(plan.toLowerCase())} <Item>Plan</Item>
        </PlanItem>
        {plan === 'TEAM' && seats > 1 ? (
          <SeatsSelect name={name} onChange={handleSeatsChange} />
        ) : (
          <PlanItem data-test-id="plan-seats">
            Single <Item>user</Item>
          </PlanItem>
        )}
        <PlanItem data-test-id="plan-quota">
          <Item>Up to</Item>
          &nbsp;{quota}&nbsp;
          <Item>
            recipients / day{' '}
            <Tooltip
              data-testid={testingVar('info-tooltip')}
              title={
                quota === 1500 ? (
                  <>
                    This email quota is subject to certain conditions.{' '}
                    <i>Click to learn more</i>
                  </>
                ) : (
                  <>
                    You can only send emails with personalized attachments to
                    100 recipients / day <i>Click to learn more</i>
                  </>
                )
              }
            >
              {quota === 1500 ? (
                <InfoButton
                  onClick={triggerQuotaModal}
                  data-testid="info-modal-toggle-button"
                  type="button"
                >
                  <TooltipStyles />
                  <InfoIcon />
                </InfoButton>
              ) : (
                <InfoLink
                  target="_blank"
                  rel="noreferrer noopener"
                  href="https://support.yet-another-mail-merge.com/hc/en-us/articles/210730829#Paid-version"
                  data-testid="info-link"
                >
                  <TooltipStyles />
                  <InfoIcon />
                </InfoLink>
              )}
            </Tooltip>
          </Item>
        </PlanItem>
      </PlanSummary>
      <Divider />
      <Invoice>
        {taxRate !== null ? (
          <>
            <Amount>
              <SecondaryInfo>Total without taxes</SecondaryInfo>
              <SecondaryCost data-test-id="total-amount-without-taxes">
                {taxRate !== undefined ? amountFormatted : '-'}
              </SecondaryCost>
            </Amount>
            <Amount>
              <SecondaryInfo>
                <span>Taxes&nbsp;</span>
                {taxRate !== undefined && <span>{`(${taxRate}%)`}</span>}
              </SecondaryInfo>
              <SecondaryCost data-test-id="taxes">
                {taxRate !== undefined ? taxesFormatted : '-'}
              </SecondaryCost>
            </Amount>
            <Amount>
              <Info>Total amount</Info>
              <Cost data-test-id="total-amount">
                {taxRate !== undefined ? totalWithTaxes : '-'}
              </Cost>
            </Amount>
          </>
        ) : (
          <Amount>
            <Info>Total amount</Info>
            <Cost data-test-id="total-amount">{amountFormatted}</Cost>
          </Amount>
        )}
        <BillFrequency>Billed yearly</BillFrequency>
      </Invoice>
    </Container>
  );
};

export default memo(OrderSummary);
