/* eslint-disable no-param-reassign */
import axios from 'axios';
import { TOKEN_KEY } from './context/AuthContext/token';

export const client = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_URL_BASE}`,
});

client.interceptors.request.use((config) => {
  const token = localStorage.getItem(TOKEN_KEY);
  // @ts-ignore
  config.headers.Authorization = token ? `Bearer ${token}` : '';
  return config;
});
