import { percentage } from 'utils/percentage';
import { formatShortDateTime } from 'utils/dates';
import { useMe } from 'data/hooks/useMe';
import { useEffect, useState } from 'react';
import { getRolloutStatus } from 'utils/getRolloutStatus';
import { Props } from './types';

const currentDate = () => formatShortDateTime(new Date());

export const useConnect = ({ campaign }: Pick<Props, 'campaign'>) => {
  const [lastUpdated, setLastUpdated] = useState(currentDate);

  useEffect(() => {
    setLastUpdated(currentDate);
  }, [campaign]);

  const placeholderStats = {
    sent: 0,
    clicked: 0,
    opened: 0,
    unsubscribed: 0,
    bounced: 0,
    responded: 0,
  };

  const { sent, clicked, opened, unsubscribed, bounced, responded } =
    campaign?.trackingInfos || placeholderStats;
  const delivered = sent - bounced;
  const percentages = {
    delivered: percentage(delivered, sent),
    opened: percentage(opened, delivered),
    clicked: percentage(clicked, delivered),
    responded: percentage(responded, delivered),
    bounced: percentage(bounced, sent),
    unsubscribed: percentage(unsubscribed, delivered),
  };

  const { me } = useMe();
  const plan = me?.plan;
  const rollout = getRolloutStatus(me);

  return {
    delivered,
    clicked,
    opened,
    responded,
    bounced,
    unsubscribed,
    percentages,
    lastUpdated,
    plan,
    rollout,
  };
};
