import React, { useEffect, useCallback, useMemo, useState } from 'react';
import { useStripe } from '@stripe/react-stripe-js';
import { AxiosError } from 'axios';
import { captureException } from '@sentry/minimal';
import { useBillingInfo } from 'data/hooks/useBilingInfo';
import { useMe } from 'data/hooks/useMe';
import { usePaymentInfo } from 'data/hooks/usePaymentInfo';
import { usePurchasedPlans } from 'data/hooks/usePurchasedPlans';
import { useStripeCheckoutSession } from 'data/hooks/useStripeCheckoutSession';
import { BusinessType } from 'data/requests/paymentInfo';
import { FormikHelpers } from 'formik';
import { BackendError } from 'model/error';

import { QueryStatus } from 'react-query';
import { useHistory } from 'react-router-dom';
import useQueryParams from 'utils/useQueryParams';
import { useDomain } from 'data/hooks/useDomain';
import { availablePlans } from '../Billing/constants';
import { getInitialValues } from './constants';
import { ConnectProps, SubscriptionForm } from './types';
import { storeTransactionData } from '../../../../utils/transactionSuccessLog';
import { getPlanAmount } from '../../../../utils/getPlanAmount';
import { decodeBase64, encodeBase64 } from '../../../../utils/encodeBase64';
import { isGmailAddress } from '../../../../utils/userUtils';

const VAT_NUMBER_IS_NOT_VALID_REG = /(.*VAT number)(.*is not valid)/;

type Error = 'plan-already-bought' | 'server-error';
const errors: {
  [Key in Error]: {
    title: string;
    message: React.ReactNode;
    type: 'WARNING' | 'ERROR';
  };
} = {
  'plan-already-bought': {
    title: `Your domain already has this plan`,
    message: `Please choose another number of users.`,
    type: 'WARNING',
  },
  'server-error': {
    title: 'We were not able to process your payment',
    message: (
      <>
        Please try again. If the error persists, please{' '}
        <a
          href="https://support.yet-another-mail-merge.com/hc/en-us/requests/new"
          target="_blank"
          rel="noreferrer noopener"
        >
          contact us
        </a>
        .
      </>
    ),
    type: 'ERROR',
  },
};

function useErrors() {
  const [error, setError] = useState<Error | null>(null);
  return {
    typeOfError: error,
    error: error ? errors[error] : null,
    setError,
  };
}

export const useConnect = ({ plan, seats }: ConnectProps) => {
  const stripe = useStripe();
  const { replace } = useHistory();
  const { me } = useMe({ keepPreviousData: true });
  const { domain } = useDomain(
    { userId: me?.email, includeStats: false },
    { keepPreviousData: true },
  );
  const [enableReinitialize, setEnableReinitialize] = useState(true);
  const onBillingInfoSettled = useCallback(
    () => setTimeout(() => setEnableReinitialize(false), 0),
    [],
  );
  const [typeOfBusiness, setTypeOfBusiness] = useState<BusinessType>('B2C');
  const [country, setCountry] = useState<string | undefined>();
  const { typeOfError, error, setError } = useErrors();
  const { billingInfo } = useBillingInfo(
    {
      userId: me?.email,
    },
    { onSettled: onBillingInfoSettled },
  );
  useEffect(() => {
    if (enableReinitialize && billingInfo) {
      setTypeOfBusiness(billingInfo.isBusinessPurchase ? 'B2B' : 'B2C');
    }
  }, [billingInfo]);
  const {
    paymentInfo,
    status,
    isFetching: isPaymentInfoFetching,
    isError: isPaymentInfoError,
  } = usePaymentInfo({
    country,
    type: typeOfBusiness,
  });
  const { getStripeSessionId } = useStripeCheckoutSession();
  const userIsWorkspace = me?.email ? !isGmailAddress(me.email) : false;
  const { purchasedPlans, status: purchasedPlansStatus } = usePurchasedPlans({
    userIsWorkspace,
    domainPlan: domain?.plan,
    domain: domain?.domain,
  });
  const params = useQueryParams();
  const initialData = params.get('data');

  const isEuropeanCountry = useMemo(() => paymentInfo?.currency === 'EUR', [
    paymentInfo,
  ]);

  const currency = useMemo(() => (isEuropeanCountry ? 'EUR' : 'USD'), [
    isEuropeanCountry,
  ]) as 'EUR' | 'USD';

  const taxRate = useMemo(() => {
    if (isPaymentInfoFetching) {
      return undefined;
    }

    if (!isEuropeanCountry) return null;

    return paymentInfo?.taxRate || 0;
  }, [isPaymentInfoFetching, paymentInfo, isEuropeanCountry]);

  const taxRateIncluded = useMemo(() => {
    return country === 'BE' ? false : typeOfBusiness === 'B2B';
  }, [country, typeOfBusiness]);

  const planId = useMemo(() => {
    const foundPlan = Object.entries(availablePlans).find(
      ([, availablePlan]) =>
        availablePlan.seats === (seats || 1) &&
        availablePlan.plan === plan &&
        availablePlan.currency === currency,
    );
    return foundPlan?.[0];
  }, [plan, seats, currency]);

  const initialValues = useMemo(() => {
    const userDomain = userIsWorkspace ? me?.email.split('@')?.[1] : undefined;
    const defaultInitialValues = getInitialValues(userDomain, plan, seats);

    if (initialData) {
      const decodedInitialData = JSON.parse(
        decodeBase64(initialData),
      ) as Partial<typeof defaultInitialValues>;
      return { ...defaultInitialValues, ...decodedInitialData };
    }

    if (billingInfo) {
      return { ...defaultInitialValues, ...billingInfo };
    }

    return defaultInitialValues;
  }, [billingInfo, plan, me?.email, userIsWorkspace, initialData]);

  useEffect(() => {
    if (enableReinitialize && initialData && initialValues) {
      setEnableReinitialize(false); // prevent billingInfo from overriding typeOfBusiness
      setTypeOfBusiness(initialValues.isBusinessPurchase ? 'B2B' : 'B2C');
    }
  }, [initialData, enableReinitialize]);

  const isLoading = purchasedPlansStatus !== QueryStatus.Success;

  const handleCountryChange = useCallback(
    (countryAbreviation) => {
      setCountry(countryAbreviation);
    },
    [setCountry, typeOfBusiness],
  );

  useEffect(() => {
    if (isPaymentInfoError) {
      setError('server-error');
    } else if (typeOfError === 'server-error') {
      setError(null);
    }
  }, [isPaymentInfoError]);

  const handleTypeOfBusinessChange = useCallback(
    (isB2B: boolean) => {
      setTypeOfBusiness(isB2B ? 'B2B' : 'B2C');
    },
    [setTypeOfBusiness],
  );

  const handleSeatsChange = useCallback((seatsGiven: number) => {
    const currentLocation = new URL(window.location.href);
    currentLocation.searchParams.set('seats', `${seatsGiven}`);
    replace(currentLocation.pathname + currentLocation.search);
  }, []);

  const handleSubmit = useCallback(
    async (
      values: SubscriptionForm,
      { setFieldError }: FormikHelpers<SubscriptionForm>,
    ) => {
      if (!me || !planId) return;

      try {
        const goBackUrl = new URL(window.location.href);
        goBackUrl.searchParams.set(
          'data',
          encodeBase64(JSON.stringify(values)),
        );

        const baseBody = {
          goBackUrl: goBackUrl.href,
          plans: [{ quantity: 1, stripePlanId: planId }],
          billingEmail: me.email,
          address: {
            country: values.country,
            street: values.street,
            city: values.city,
            state: values.state,
            postalCode: values.code,
          },
          isB2BTransaction: values.isBusinessPurchase,
          vatNumber: values.isBusinessPurchase ? values.vat : undefined,
          customerName: values.name,
          userName: me.name,
        };
        const businessPurchaseBody = {
          companyName: values.companyName,
        };
        const teamBody = {
          domain: values.domain,
        };
        const isWorkspacePlan = plan === 'TEAM';
        const body = {
          ...baseBody,
          ...(values.isBusinessPurchase ? businessPurchaseBody : {}),
          ...(isWorkspacePlan ? teamBody : {}),
        };
        const sessionId = await getStripeSessionId(body);
        if (sessionId) {
          storeTransactionData({
            tax: paymentInfo?.taxRate,
            basePrice: getPlanAmount(plan, seats, currency),
            seats,
            plan,
            sessionId,
          });
          stripe?.redirectToCheckout({ sessionId });
        }
      } catch (submissionError) {
        captureException(submissionError);
        const axiosError = ((submissionError as AxiosError).response
          ?.data as BackendError).error;
        if (axiosError != null) {
          if (
            axiosError.message.includes('tax_id_invalid') ||
            VAT_NUMBER_IS_NOT_VALID_REG.test(axiosError.message)
          ) {
            setFieldError('vat', 'This VAT ID doesn’t seem to be valid.');
          } else if (
            axiosError.message.includes(
              'New plan has the same seats of the existing plan',
            )
          ) {
            setError('plan-already-bought');
          } else {
            setError('server-error');
          }
        } else {
          setError('server-error'); // probably client side error
        }
      }
    },
    [me, planId, plan, seats, stripe, purchasedPlans, setError],
  );

  const disableCheckoutButton = useMemo(() => {
    return !stripe || isPaymentInfoFetching || isPaymentInfoError;
  }, [stripe, isPaymentInfoFetching, isPaymentInfoError]);

  return {
    stripe,
    currency,
    isEuropeanCountry,
    status,
    taxRate,
    taxRateIncluded,
    typeOfError,
    error,
    setError,
    enableReinitialize,
    initialValues,
    isLoading,
    handleSeatsChange,
    handleCountryChange,
    handleTypeOfBusinessChange,
    handleSubmit,
    isPaymentInfoFetching,
    disableCheckoutButton,
  };
};
