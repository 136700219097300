import React, { FC } from 'react';

import { ReactComponent as ExternalLinkIcon } from 'assets/images/icons/external-link.svg';
import { ReactComponent as CancelIcon } from 'assets/images/icons/cancel.svg';
import { ReactComponent as InfoIcon } from 'assets/images/icons/check.svg';

import Modal from 'components/Modal';
import testingVar from 'utils/testingVar';

import {
  CancelButton,
  Column,
  Content,
  Error,
  ErrorIcon,
  WarningIcon,
  IconWrapper,
  Info,
  Label,
  Link,
  LinkIcon,
  SmallPlaceholder,
  TitlePlaceholder,
} from './styles';

import { useConnect } from './connect';
import { Props } from './types';
import ScheduledCampaignError from './errors';
import { isErrorStatus } from '../../../../../utils/scheduledCampaigns';

const ScheduledCampaignDetail: FC<Props> = () => {
  const {
    scheduledMailMerge,
    deliveryDate,
    cancelScheduledMailMerge,
    handle,
    isOpen,
    canCancelMerge,
    isOutOfQuota,
  } = useConnect();

  return (
    <Modal
      title={scheduledMailMerge?.title || <TitlePlaceholder />}
      open={isOpen}
      onClose={handle.close}
    >
      {scheduledMailMerge && isErrorStatus(scheduledMailMerge.status) && (
        <Error $variant="error">
          <ErrorIcon />
          {ScheduledCampaignError[scheduledMailMerge.status](
            scheduledMailMerge,
          )}
        </Error>
      )}
      {scheduledMailMerge && isOutOfQuota && (
        <Error $variant="warning">
          <WarningIcon />
          <span>
            Your mail merge could not be sent in full because your sending quota
            was insufficient. It will resume as soon as your quota resets.
          </span>
        </Error>
      )}
      <Content data-test-id={testingVar('scheduled-details')}>
        <Info>
          <Column>
            <Label>emails</Label>
            {scheduledMailMerge ? (
              <span>{scheduledMailMerge.numberOfEmailsToSend}</span>
            ) : (
              <SmallPlaceholder />
            )}
          </Column>
          <Column>
            <Label>Delivery date</Label>
            <span>
              {isOutOfQuota ? 'Upon quota reset' : deliveryDate || '-'}
            </span>
          </Column>
        </Info>
      </Content>
      <Link
        href={scheduledMailMerge?.spreadsheetLink || '#'}
        target="_blank"
        rel="noopener noreferrer"
      >
        <IconWrapper>
          <ExternalLinkIcon />
        </IconWrapper>
        <span>Open in Google Sheets</span>
        <LinkIcon />
      </Link>
      <CancelButton
        $variant={canCancelMerge ? 'error' : 'info'}
        data-test-id={testingVar('submit-cancel')}
        onClick={canCancelMerge ? cancelScheduledMailMerge : handle.close}
        disabled={!scheduledMailMerge}
      >
        <IconWrapper>
          {canCancelMerge ? <CancelIcon /> : <InfoIcon />}
        </IconWrapper>
        <span>{canCancelMerge ? 'Cancel mail merge' : 'Ok'}</span>
      </CancelButton>
    </Modal>
  );
};

export default ScheduledCampaignDetail;
