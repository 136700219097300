import { client } from 'data/client';
import { Input } from 'model/stripeCheckoutSession';

const API_VERSION = 'v2';

type StripeCheckoutSessionDto = Input;

type StripeCheckoutSessionResponse = {
  stripeCheckoutSessionId: string;
};

export async function getStripeCheckoutSession(body: StripeCheckoutSessionDto) {
  const { data } = await client.post<StripeCheckoutSessionResponse>(
    `/payment/stripeCheckoutSession`,
    body,
    {
      baseURL: `${process.env.REACT_APP_BILLING_API_URL}${process.env.REACT_APP_BILLING_API_URL_BASE}${API_VERSION}`,
    },
  );
  return data.stripeCheckoutSessionId;
}
