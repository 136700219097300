import { client } from 'data/client';
import { Input } from 'model/campaign';

export type GetMailMergesDto = Partial<{
  limit: number;
  offset: number;
  'lastTimeUsed[gte]': string;
  'lastTimeUsed[lte]': string;
  fields: keyof GetMailMergesDto;
}>;

type GetCampaignsResponse = {
  items: Input[];
};

function denormalizeParams(params: GetMailMergesDto) {
  const denormalizedParams = { ...params };
  if (params['lastTimeUsed[gte]']) {
    denormalizedParams['lastTimeUsed[gte]'] = new Date(
      params['lastTimeUsed[gte]'],
    ).toISOString();
  }
  if (params['lastTimeUsed[lte]']) {
    denormalizedParams['lastTimeUsed[lte]'] = new Date(
      params['lastTimeUsed[lte]'],
    ).toISOString();
  }
  return denormalizedParams;
}

export async function getMailMerges(
  key: string,
  userId: string,
  params: GetMailMergesDto,
) {
  const denormalizedParams = denormalizeParams(params);
  const { data } = await client.get<GetCampaignsResponse>(
    `/users/${userId}/campaigns`,
    {
      params: denormalizedParams,
    },
  );
  return data.items;
}
