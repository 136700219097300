import React, { useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { GoogleLogin } from 'react-google-login';
import GoogleButton from 'components/GoogleButton';

import { useAuth, GOOGLE_SCOPE } from 'data/context/AuthContext';
import testingVar from 'utils/testingVar';

import TrustpilotScore from 'assets/images/icons/trustpilot-score.png';
import TosAgreementText from '../../TosAgreementText';

import { stepOneLoad, stepOneSignUp } from '../events';

import {
  Card,
  Title,
  ImageWrapper,
  YammImage,
  LinkWrapper,
} from '../../styles';
import { Text } from '../styles';

const StepOne = () => {
  const { onSignUp, onFailure, clientId } = useAuth();
  const history = useHistory();
  useEffect(() => {
    stepOneLoad();
    history.replace('signup');
  }, []);

  return (
    <>
      <Card>
        <ImageWrapper>
          <YammImage />
        </ImageWrapper>
        <Title>Welcome to YAMM</Title>
        {clientId ? (
          <GoogleLogin
            clientId={clientId}
            render={({ onClick, disabled }) => (
              <GoogleButton
                variant="SIGN_UP"
                data-test-id={testingVar('submit-login')}
                onClick={() => {
                  stepOneSignUp();
                  onClick();
                }}
                disabled={disabled}
              />
            )}
            scope={GOOGLE_SCOPE}
            onSuccess={onSignUp}
            onFailure={onFailure}
            prompt="select_account"
          />
        ) : null}
        <TosAgreementText />
      </Card>
      <Text>
        Already have a YAMM account? <Link to="/login">Sign in</Link>
      </Text>
      <LinkWrapper href="https://www.trustpilot.com/review/yamm.com">
        <img alt="trustpilot-score" src={TrustpilotScore} />
      </LinkWrapper>
    </>
  );
};

export default StepOne;
