import React from 'react';
import { QueryStatus } from 'react-query';
import useConnect from '../connect';
import { Plan } from '../styles';

export function CurrentPlans({
  managePlans,
  purchasedPlans,
  email,
  isLoadingPurchasedPlans,
  managedPlanStatus,
  userPlan,
}: {
  managePlans: ReturnType<typeof useConnect>['managePlans'];
  purchasedPlans: ReturnType<typeof useConnect>['purchasedPlans'];
  email: ReturnType<typeof useConnect>['email'];
  isLoadingPurchasedPlans: boolean;
  managedPlanStatus: QueryStatus;
  userPlan: ReturnType<typeof useConnect>['userPlan'];
}) {
  if (isLoadingPurchasedPlans) {
    // the only important prop here is `isLoading` the rest are required but mean nothing
    return <Plan isLoading plan="FREE" email={email} quota={50} />;
  }

  return (
    <>
      {purchasedPlans &&
        purchasedPlans.map((purchasedPlan) => {
          return (
            <Plan
              isCurrent
              key={purchasedPlan.subscriptionId}
              amount={purchasedPlan.amount}
              plan={purchasedPlan.planName}
              seats={purchasedPlan.seats}
              quota={purchasedPlan.planName === 'PERSONAL' ? 400 : 1500}
              currency={purchasedPlan.currency}
              paymentSource={purchasedPlan.paymentSource}
              email={purchasedPlan.domain || email}
              managePlanIsLoading={managedPlanStatus === QueryStatus.Loading}
              taxValue={purchasedPlan.taxValue}
              endTimestamp={purchasedPlan.endTimestamp}
              status={purchasedPlan.status}
              subscriptionId={purchasedPlan.subscriptionId}
              onManage={
                managePlans?.[
                  purchasedPlan.subscriptionId as keyof typeof managePlans
                ]
              }
            />
          );
        })}

      {userPlan === 'FREE' && (
        <Plan isCurrent plan="FREE" email={email} quota={50} />
      )}
    </>
  );
}
