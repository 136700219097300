import { useAuth } from 'data/context/AuthContext';
import { useCancelAutoRenewal } from 'data/hooks/useCancelAutoRenewal';
import { useMe } from 'data/hooks/useMe';
import { useCallback, useEffect, useState } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import useQueryParams from 'utils/useQueryParams';

export const useConnect = () => {
  const {
    token,
    onLogin,
    onFailure,
    clientId,
    getUserStatus,
    error,
  } = useAuth();
  const { me } = useMe();
  const { push, replace } = useHistory();
  const { url } = useRouteMatch();
  const query = useQueryParams();
  const commitCancelRenewal = useCancelAutoRenewal();
  const [isLoading, setIsLoading] = useState(false);
  const success = query.get('success');
  const subscriptionId =
    me && 'license' in me ? me.license.subscriptionId : undefined;

  useEffect(() => {
    setIsLoading(getUserStatus === 'loading' && !error);
  }, [getUserStatus, error]);

  const cancelRenewal = useCallback(async () => {
    setIsLoading(true);
    try {
      await commitCancelRenewal({ subscriptionId });
      push(`${url}?success=1`);
    } catch {
      push(`${url}?success=0`);
    } finally {
      setIsLoading(false);
    }
  }, [commitCancelRenewal, push, url, subscriptionId]);

  return {
    isLoggedIn: !!token,
    clientId,
    onLogin,
    onFailure,
    isLoading,
    error,
    cancelRenewal,
    success,
    me,
    replace,
  };
};
