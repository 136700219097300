import { NewPlan } from 'model/plan';

export function getPlanQuota(plan: NewPlan) {
  if (plan === 'FREE') {
    return 50;
  }

  if (plan === 'PERSONAL') {
    return 400;
  }

  return 1500;
}
