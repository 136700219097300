import { useQuery } from 'react-query';
import { normalizeCampaign } from 'model/campaign';
import { getMailMerges, GetMailMergesDto } from 'data/requests/mailMerges';
import { subMonths, format } from 'date-fns';

export const useMailMerges = (userId?: string) => {
  const params: GetMailMergesDto = {
    'lastTimeUsed[gte]': format(subMonths(new Date(), 12), 'yyyy-MM-dd'),
  };
  const { data, status } = useQuery(
    ['mail-merges', userId, params],
    getMailMerges,
    {
      enabled: !!userId,
    },
  );
  const mailMerges = (data || []).map(normalizeCampaign);
  return { mailMerges, status };
};
