/* eslint-disable import/no-webpack-loader-syntax */
import React, { memo } from 'react';
import { ExporterComponent } from 'components/DesktopTable/types';
import ExportToCsv from 'components/ExportToCsv';
import transformerWorkerUrl from 'worker-plugin/loader?name=unsubscriptions!../transformer';
import { useWorker } from 'utils/useWorker';
import { Unsubscription } from 'model/unsubscription';
import { captureException } from '@sentry/minimal';
import { UnsubscriptionsWorker } from '../transformer';
import { useStackDriverLogging } from '../../../../data/context/StackDriverLogging';
import { getUserDataForLogging } from '../../../../utils/logging';

const Exporter: ExporterComponent<Unsubscription[]> = ({ data }) => {
  const { worker: transformer } = useWorker<UnsubscriptionsWorker>(
    transformerWorkerUrl,
    'UnsubscriptionsWorker',
  );
  const { getUser, logToStackDriver } = useStackDriverLogging();
  const onDone = () => {
    // log to the stack driver unsubscribed emails
    try {
      const user = getUserDataForLogging(getUser());
      logToStackDriver({
        timestamp: Date.now(),
        user_id: user.user_id,
        user_installation_timestamp: user.user_installation_timestamp,
        plan: user.plan,
        is_biz_user: user.is_biz_user,
        domain: user.domain,
        event_type: 'csv_export',
        export_type: 'unsubscription',
        message: 'User exported unsubscription list (csv_export)',
        list_of_merge_ids: data.map((d) => d.id),
      });
    } catch (e) {
      captureException(e);
    }
  };
  return (
    <ExportToCsv
      data={data}
      transformer={transformer}
      fileName={`Exported_Unsubscriptions_${new Date().toDateString()}`}
      onDone={onDone}
    />
  );
};

export default memo(Exporter);
