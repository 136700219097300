import currencyjs from 'currency.js';
import { useField } from 'formik';
import { useCallback, useMemo } from 'react';
import { LogicProps, Props } from './types';
import { formatPrice } from '../../utils/priceFormat';
import { useQuotaConditionsModalTrigger } from '../../containers/Common/WrongPlanChoice/QuotaConditionsModal/useQuotaConditionsModalTrigger';

export const getFormattedValue = (value: number, currency: Props['currency']) =>
  formatPrice(value, currency?.toUpperCase() || 'USD');

type FormattedAmountParams = Pick<
  Props,
  'amount' | 'currency' | 'taxRate' | 'taxRateIncluded'
>;

export const useFormattedAmounts = ({
  amount,
  currency,
  taxRate,
  taxRateIncluded,
}: FormattedAmountParams) => {
  const amountFormatted = useMemo(() => getFormattedValue(amount, currency), [
    amount,
    currency,
  ]);

  const taxes = useMemo(() => {
    return taxRate !== undefined && taxRate !== null
      ? currencyjs(amount).multiply(taxRateIncluded ? 0 : taxRate / 100)
      : undefined;
  }, [taxRate, taxRateIncluded, amount, currency]);

  const taxesFormatted = useMemo(
    () => (taxes ? getFormattedValue(taxes.value, currency) : undefined),
    [taxes, currency],
  );

  const totalWithTaxes = useMemo(
    () =>
      taxes !== undefined
        ? getFormattedValue(taxes.add(amount).value, currency)
        : undefined,
    [taxes, amount, currency],
  );

  return {
    amountFormatted,
    taxesFormatted,
    totalWithTaxes,
  };
};

const useLogic = ({ name, onSeatsChange }: LogicProps) => {
  const { trigger } = useQuotaConditionsModalTrigger();

  const [{ value }] = useField<number>(name);
  const handleSeatsChange = useCallback(
    (seats: number) => {
      if (onSeatsChange) {
        onSeatsChange(seats);
      }
    },
    [onSeatsChange],
  );

  return {
    seats: value,
    handleSeatsChange,
    triggerQuotaModal: trigger,
  };
};

export default useLogic;
