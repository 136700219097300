import { useEnqueueSnackbar } from 'components/Snackbar/useEnqueueSnackbar';
import { useImportDraft } from 'data/hooks/useImportDraft';
import { useMe } from 'data/hooks/useMe';
import { useTemplates } from 'data/hooks/useTemplates';
import { PostImportDraftDto } from 'data/requests/templates';
import { useCallback, useMemo, useState } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { permissionGranted } from 'utils/permissionGranted';
import { up } from 'utils/Paths';
import { useAuth } from 'data/context/AuthContext';
import { useImportedDrafts } from 'data/context/TemplatesContext';
import { getAuthUserIndex } from '../../../utils/cookiesUtils';

const COMPOSE_SCOPE = 'https://www.googleapis.com/auth/gmail.compose';

export const useConnect = () => {
  const { url } = useRouteMatch();
  const { me } = useMe();
  const { askForPermission } = useAuth();
  const { templates, status } = useTemplates();
  const { mutate, status: importStatus } = useImportDraft();
  const { push, goBack } = useHistory();
  const enqueue = useEnqueueSnackbar();
  const matches = useRouteMatch<{ id: string }>(`${url}/:id`);
  const [importingId, setImportingId] = useState<string | undefined>();
  const { importedDrafts, setImportedDrafts } = useImportedDrafts();

  const loggedUserIndex = getAuthUserIndex();

  const openedTemplate = useMemo(() => {
    if (!matches) return undefined;
    const { id } = matches.params;
    const found = templates?.find((template) => template.id === id);
    return found;
  }, [matches, templates]);

  const onClose = useCallback(() => {
    const currentUrl = matches?.url;
    if (!currentUrl) {
      goBack();
    } else {
      push(up(currentUrl));
    }
  }, [push, goBack, matches]);

  const triggerImport = useCallback(
    async ({ fromTemplate }: Pick<PostImportDraftDto, 'fromTemplate'>) => {
      if (!me?.email) return;
      if (!permissionGranted(COMPOSE_SCOPE)) {
        try {
          await askForPermission(COMPOSE_SCOPE);
        } catch {
          return;
        }
      }
      let commit = true;
      setImportingId(fromTemplate);
      const performImport = async () => {
        if (!commit) return;
        try {
          const response = await mutate({
            userId: me.email,
            fromTemplate,
            source: 'GALLERY',
          });
          if (response?.messageId) {
            enqueue('Gmail draft created');
            setImportedDrafts((prev) => ({
              ...prev,
              [fromTemplate]: response.messageId,
            }));
          } else {
            enqueue('Gmail draft could not be created', { variant: 'error' });
          }
        } catch {
          enqueue('Gmail draft could not be created', { variant: 'error' });
        } finally {
          setImportingId(undefined);
          if (matches) {
            onClose();
          }
        }
      };
      enqueue('Creating Gmail draft...', {
        autoHideDuration: 3000,
        onExited: performImport,
        undoId: `import-${fromTemplate}`,
        onUndo: () => {
          commit = false;
          setImportingId(undefined);
        },
      });
    },
    [
      me,
      mutate,
      enqueue,
      matches,
      onClose,
      askForPermission,
      setImportedDrafts,
    ],
  );

  const triggerImportModal = useCallback(async () => {
    if (!me?.email || !matches) return;
    const fromTemplate = matches.params.id;
    triggerImport({ fromTemplate });
  }, [me, triggerImport, matches]);

  return {
    templates,
    status,
    importStatus,
    importingId,
    url,
    isModalOpen: !!matches,
    openedTemplate,
    importedDrafts,
    loggedUserIndex,
    handle: {
      close: onClose,
      import: triggerImport,
      importFromModal: triggerImportModal,
    },
  };
};
