import { getPlanAmount } from 'utils/getPlanAmount';
import { getPlanQuota } from 'utils/getPlanQuota';
import { Props as LogicProps } from './types';

export const useLogic = ({
  plan,
  seats,
  currency,
}: LogicProps & { currency: 'EUR' | 'USD' }) => {
  const quota = getPlanQuota(plan);
  const amount = getPlanAmount(plan, seats, currency);
  return { amount, quota };
};
