import { client } from 'data/client';
import { Input } from 'model/campaign';
import { Input as PollInput } from 'model/poll';

type GetCampaignResponse = Input;

export async function getMailMerge(
  key: string,
  userId: string,
  campaignId: string,
) {
  const { data } = await client.get<GetCampaignResponse>(
    `/users/${userId}/campaigns/${campaignId}`,
  );
  return data;
}

type GetCampaignPollResponse = {
  items: PollInput[];
};

export async function getMailMergePoll(
  key: string,
  userId: string,
  campaignId: string,
) {
  const { data } = await client.get<GetCampaignPollResponse>(
    `/users/${userId}/campaigns/${campaignId}/pollResponses`,
  );
  return data.items;
}
