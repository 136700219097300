import { NewPlan } from 'model/plan';
import { PRICES } from '../../data/prices';

export function getPlanAmount(
  plan: NewPlan,
  seats?: number,
  currency: 'USD' | 'EUR' = 'USD',
) {
  if (plan === 'PERSONAL') {
    return PRICES[currency].PERSONAL;
  }

  if (plan === 'PROFESSIONAL') {
    return PRICES[currency].PROFESSIONAL;
  }

  if (plan === 'TEAM') {
    switch (seats) {
      case 100:
        return PRICES[currency].TEAM_100;
      case 50:
        return PRICES[currency].TEAM_50;
      case 25:
        return PRICES[currency].TEAM_25;
      case 10:
        return PRICES[currency].TEAM_10;
      case 5:
      default:
        return PRICES[currency].TEAM_5;
    }
  }

  return PRICES.USD.PERSONAL;
}
