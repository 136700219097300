import { NewPlan } from './plan';
import { PRICES } from '../data/prices';

export type Input = {
  legalEntity: 'US' | 'BE';
  subscriptionId: string;
  creationTimestamp: number;
  endTimestamp: number;
  paymentSource: 'stripe' | 'paypal';
  status: 'ACTIVE' | 'CANCELED';
  type: 'USER' | 'DOMAIN';
  maxUsers?: number;
  domain?: string;
  currency?: string;
  price?: number;
  taxValue?: number;
};

const teamAmounts = {
  5: PRICES.USD.TEAM_5,
  10: PRICES.USD.TEAM_10,
  25: PRICES.USD.TEAM_25,
  50: PRICES.USD.TEAM_50,
  100: PRICES.USD.TEAM_100,
};

const getDefaultPlanPrice = (planName: string, maxUsers?: number): number => {
  switch (planName) {
    case 'PROFESSIONAL':
      return PRICES.USD.PROFESSIONAL;
    case 'TEAM':
      return teamAmounts[maxUsers as keyof typeof teamAmounts];
    case 'UNLIMITED':
      return 2000;
    case 'PERSONAL':
    default:
      return PRICES.USD.PERSONAL;
  }
};
const getPlanQuota = (planName: string): number => {
  switch (planName) {
    case 'PROFESSIONAL':
    case 'TEAM':
    case 'UNLIMITED':
      return 1500;
    case 'PERSONAL':
    default:
      return 400;
  }
};

export const normalizePurchasedPlan = (
  { maxUsers, ...input }: Input,
  userIsWorkspace?: boolean,
) => {
  let planName: NewPlan = 'PERSONAL';
  let quota = null;
  let amount = null;

  if (!maxUsers && input.type !== 'DOMAIN' && userIsWorkspace) {
    planName = 'PROFESSIONAL';
  }

  if (maxUsers && input.type === 'DOMAIN' && maxUsers > 0) {
    planName = 'TEAM';
  }

  if (input.subscriptionId === 'unlimited') {
    planName = 'UNLIMITED';
  }

  quota = getPlanQuota(planName);

  if (input.price != null) {
    // price is in cents
    amount = input.price / 100;
  } else {
    amount = getDefaultPlanPrice(planName, maxUsers);
  }

  return {
    ...input,
    planName: planName as NewPlan,
    amount,
    quota,
    seats: maxUsers,
    isOwner: true,
  };
};

export type PurchasedPlan = ReturnType<typeof normalizePurchasedPlan>;
