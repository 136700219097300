import { useMe } from 'data/hooks/useMe';
import { useAuth } from 'data/context/AuthContext';
import testingVar from 'utils/testingVar';
import { useCannon } from 'data/hooks/useCannon';

export const useConnect = () => {
  const { switchAccount, logout } = useAuth();
  const { me } = useMe({ keepPreviousData: true });
  const { cannon } = useCannon('sidebar');
  const isTesting = testingVar('');

  return { isTesting, switchAccount, logout, me, cannon };
};
