import React, { FC, memo } from 'react';
import { ReactComponent as MailMerge } from 'assets/images/icons/mail-merge-tutorial.svg';
import { ReactComponent as Unsubscription } from 'assets/images/icons/unsubscription.svg';
import { ReactComponent as Team } from 'assets/images/icons/team.svg';
import { ReactComponent as Tutorial } from 'assets/images/icons/mail-merge.svg';
import testingVar from 'utils/testingVar';
import { clickTutorial } from 'containers/Guest/SignUp/events';
import {
  Container,
  Header,
  Logo,
  Title,
  Navigation,
  Item,
  ItemIcon,
  TopSection,
  BottomSection,
} from './styles';
import { Props } from './types';
import { ReactComponent as Template } from '../../assets/images/icons/template.svg';
import SmallCannonNavBanner from '../SmallCannonNavBanner';

const DesktopMenu: FC<Props> = ({
  canAccessTeamReport,
  cannon,
  showTutorial,
  ...props
}) => {
  return (
    <Container {...props}>
      <TopSection>
        <Header>
          <Logo />
          <Title>Yamm</Title>
        </Header>
        <Navigation>
          <Item to="/mail-merges">
            <ItemIcon as={MailMerge} />
            Mail merges
          </Item>
          <Item
            data-test-id={testingVar('view-unsubscriptions')}
            to="/unsubscriptions"
          >
            <ItemIcon as={Unsubscription} />
            Unsubscriptions
          </Item>
          <Item data-test-id={testingVar('view-templates')} to="/templates">
            <ItemIcon as={Template} />
            Templates
          </Item>
          {canAccessTeamReport && (
            <Item
              data-test-id={testingVar('view-team-report')}
              to="/team-report"
            >
              <ItemIcon as={Team} />
              Team report
            </Item>
          )}
          {showTutorial && (
            <Item to="/get-started" onClick={clickTutorial}>
              <ItemIcon as={Tutorial} />
              Install YAMM
            </Item>
          )}
        </Navigation>
      </TopSection>
      <BottomSection>
        {cannon && cannon.enabled && <SmallCannonNavBanner cannon={cannon} />}
      </BottomSection>
    </Container>
  );
};

export default memo(DesktopMenu);
