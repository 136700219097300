export const PRICES = {
  EUR: {
    PERSONAL: 25,
    PROFESSIONAL: 50,
    TEAM_5: 150,
    TEAM_10: 240,
    TEAM_25: 500,
    TEAM_50: 1000,
    TEAM_100: 2000,
  },
  USD: {
    PERSONAL: 25,
    PROFESSIONAL: 50,
    TEAM_5: 150,
    TEAM_10: 240,
    TEAM_25: 500,
    TEAM_50: 1000,
    TEAM_100: 2000,
  },
};
