export function getRolloutStatus(me: any) {
  if (!me) {
    return {
      clickRemoval: false,
    };
  }

  return {
    clickRemoval:
      !!me?.installationTimestamp &&
      me?.installationTimestamp >= Date.parse('2020/01/01'),
  };
}
