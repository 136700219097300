import { useMe } from 'data/hooks/useMe';
import { useMemo } from 'react';
import { useRouteMatch } from 'react-router-dom';
import testingVar from 'utils/testingVar';
import { getRolloutStatus } from 'utils/getRolloutStatus';
import { getColumns } from './table/columns';

export const useConnect = () => {
  const { url } = useRouteMatch();
  const { me } = useMe();

  const plan = me?.plan;
  const rollout = getRolloutStatus(me);

  const columns = useMemo(() => getColumns({ url, plan, rollout }), [url]);
  const mailMergesTestingVar = testingVar('mail-merges');

  return { columns, mailMergesTestingVar, url, plan, rollout };
};
