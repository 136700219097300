import { useMediaQuery } from '@material-ui/core';

import React from 'react';
import MessageCard from 'components/MessageCard';

import { from } from 'styles/media';
import StaticLayout from 'containers/Common/StaticLayout';
import GoogleLogin from 'react-google-login';
import GoogleButton from 'components/GoogleButton';
import { GOOGLE_SCOPE } from 'data/context/AuthContext';
import Loader from 'components/Loader';
import testingVar from 'utils/testingVar';
import Error from './Error';
import Success from './Success';
import { useConnect } from './connect';
import {
  Text,
  Error as ErrorMessage,
  ConfirmButton,
  LoginMessage,
  Centered,
} from './styles';

const StopAutomaticRenewal = () => {
  const {
    isLoggedIn,
    clientId,
    isLoading,
    onLogin,
    onFailure,
    cancelRenewal,
    error,
    success,
    me,
    replace,
  } = useConnect();
  const isMobile = !useMediaQuery(from.mobile);

  if (
    me &&
    (('license' in me && me.license.paymentSource !== 'PAYPAL') ||
      !('license' in me))
  ) {
    replace('/');
    return null;
  }

  if (success === '0') {
    return <Error />;
  }

  if (success === '1') {
    return <Success />;
  }

  const textContent = (
    <>
      This action is permanent, if you wish to re-enable the automatic renewal,
      you have to purchase a new plan from our{' '}
      <a
        href={`${process.env.REACT_APP_MARKETING_WEBSITE_URL}/pricing`}
        target="_blank"
        rel="nofollow noopener noreferrer"
      >
        Pricing page
      </a>
    </>
  );

  return (
    <StaticLayout data-test-id={testingVar('stop-automatic-renewal')}>
      <MessageCard
        title="Are you sure you want to stop the automatic renewal?"
        subtitle={
          <>
            <Centered>
              {clientId && !isLoading && !isLoggedIn ? (
                <>
                  <LoginMessage data-test-id={testingVar('login')}>
                    Sign in with your Google account to stop the automatic
                    renewal.
                  </LoginMessage>
                  <GoogleLogin
                    clientId={clientId}
                    render={({ onClick, disabled }) => (
                      <GoogleButton disabled={disabled} onClick={onClick} />
                    )}
                    scope={GOOGLE_SCOPE}
                    onSuccess={() => onLogin()}
                    onFailure={onFailure}
                  />
                </>
              ) : null}
              {isLoading ? <Loader size={36} /> : null}
              {error && !isLoading ? (
                <ErrorMessage>{error}</ErrorMessage>
              ) : null}
              {isLoggedIn && !isLoading ? (
                <ConfirmButton
                  onClick={cancelRenewal}
                  data-test-id={testingVar('confirm-cancellation')}
                >
                  Confirm cancellation
                </ConfirmButton>
              ) : null}
            </Centered>
          </>
        }
        text={isMobile ? '' : textContent}
      />
      {isMobile && <Text>{textContent}</Text>}
    </StaticLayout>
  );
};

export default StopAutomaticRenewal;
