import StatsCard from 'components/StatsCard';
import React from 'react';
import { from, useMediaQuery } from 'styles/media';
import DataView from 'components/DataView';
import MobileTable from 'components/MobileTable';
import UserRow from 'components/MobileTable/UserRow';
import testingVar from 'utils/testingVar';
import {
  Container,
  Title,
  StatsContainer,
  TooltipStyles,
  Tooltip,
  InfoIcon,
  EmailsSentText,
} from '../MailMerges/styles';
import { DesktopTable, Header, Subtitle } from './styles';
import { useConnect } from './connect';
import Empty from './table/empty';
import NotFound from './table/notFound';
import Exporter from './table/exporter';
import Search from './table/filter';
import { plurify } from '../../../utils/plurify';

const TeamReport = () => {
  const { me, domain, columns, users, status } = useConnect();
  const isMobile = !useMediaQuery(from.tablet);

  if (me && me.plan !== 'DOMAIN_PAID') return null;

  return (
    <Container>
      <Header>
        <Title>Team Report</Title>
        <Subtitle>
          This lists anyone who has used YAMM in the domain{' '}
          <a href={`https://${domain?.domain}`}>{domain?.domain}</a>
        </Subtitle>
      </Header>
      <StatsContainer>
        <TooltipStyles />
        <StatsCard
          variant="team"
          number={domain?.totalMembers}
          text={`Active ${plurify(domain?.totalMembers, 'user')}`}
          data-test-id={testingVar('active-users')}
        />
        <StatsCard
          variant="emails30"
          number={domain?.stats.totalEmailsSentThisMonth}
          content={
            <EmailsSentText>
              {plurify(domain?.stats.totalEmailsSentThisMonth, 'Email')} sent
              the last 30 days
              <Tooltip title={<>Excluding form submission notifications.</>}>
                <InfoIcon />
              </Tooltip>
            </EmailsSentText>
          }
          data-test-id={testingVar('emails-30')}
        />
        <StatsCard
          variant="emails"
          number={domain?.stats.totalEmailsSent}
          content={
            <EmailsSentText>
              {plurify(domain?.stats.totalEmailsSent, 'Email')} sent all time
              <Tooltip title={<>Excluding form submission notifications.</>}>
                <InfoIcon />
              </Tooltip>
            </EmailsSentText>
          }
          data-test-id={testingVar('emails-all')}
        />
      </StatsContainer>
      <DataView>
        {isMobile ? (
          <MobileTable
            data={users}
            columns={columns}
            loading={status === 'loading'}
            row={(item) => (
              <UserRow
                key={item.email}
                name={item.name}
                initials={item.initials}
                photo={item.photo}
                date={item.lastTimeUsed}
                sentMonthly={item.stats.totalEmailsSentThisMonth}
                sentAll={item.stats.totalEmailsSent}
              />
            )}
            empty={<Empty />}
            notFound={<NotFound />}
            filter={Search}
            exporter={Exporter}
          />
        ) : (
          <DesktopTable
            data={users}
            loading={status === 'loading'}
            columns={columns}
            empty={<Empty />}
            notFound={<NotFound />}
            filter={Search}
            exporter={Exporter}
            disableFilter
          />
        )}
      </DataView>
    </Container>
  );
};

export default TeamReport;
