import React, { createContext, ReactNode, useContext } from 'react';
import { User } from '../../../model/user';
import { sendLog } from '../../../utils/logToStackdriver';
import { useMe } from '../../hooks/useMe';

type StackDriverLoggingProps = {
  logToStackDriver: (payload: any) => void;
  getUser: () => User | null;
};

export const StackDriverLoggingContext = createContext<StackDriverLoggingProps>(
  {
    logToStackDriver: () => {},
    getUser: () => null,
  },
);

export const useStackDriverLogging = () =>
  useContext(StackDriverLoggingContext);

export const StackDriverLoggingProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const { me } = useMe({ keepPreviousData: true });
  const logToStackDriver = (payload: any) => sendLog(payload);
  const getUser = () => me;

  return (
    <StackDriverLoggingContext.Provider value={{ logToStackDriver, getUser }}>
      {children}
    </StackDriverLoggingContext.Provider>
  );
};
