import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import YammVideo from 'assets/video/yamm-instruction.webm';

import { Button, Link } from '../styles';
import { Card, Title, Text, Video, ButtonLink } from './styles';

import {
  stepThreeLoad,
  stepThreeSheets,
  stepThreeDashboard,
  onboardingSend,
} from '../events';

const StepThree = () => {
  const history = useHistory();
  useEffect(() => {
    onboardingSend();
    stepThreeLoad();
    history.replace('get-started');
  }, []);

  return (
    <>
      <Card>
        <Title>Open Google Sheets to launch YAMM</Title>
        <Text>
          From Google Sheets, open the <b>Extensions</b> menu, look for
          <b>&nbsp;Yet Another Mail Merge</b> and then hit{' '}
          <b>Start Mail Merge</b>.
        </Text>
        <Video muted autoPlay loop src={YammVideo} />
        <ButtonLink onClick={stepThreeSheets} href="https://sheets.new">
          <Button>Open Google Sheets</Button>
        </ButtonLink>
      </Card>
      <Link onClick={stepThreeDashboard} to="/">
        Take me to my YAMM dashboard
      </Link>
    </>
  );
};

export default StepThree;
